
































































































import Vue from "vue";

export default Vue.extend({
  metaInfo: () => ({
    title: "Giriş"
  }),
  props: {
    course: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      panel: [0]
    };
  }
});
